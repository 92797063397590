<template>
  <div>
    <b-button
      v-b-modal.modal-filtro
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="info"
      class="mb-1"
      @click="clearFilter"
    >
      Filtro
    </b-button>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col md="4">
              <b-row class="mt-1">
                <b-col>
                  <div
                    class="d-flex align-items-center mr-2 hoverzim"
                    @click="filterHeader"
                  >
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ count.liquidado.count }}
                      </h5>
                      <small>Parcelas Liquidadas</small>
                    </div>
                  </div>
                </b-col>

                <b-col>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ valorBr(count.liquidado.total, true) }}
                      </h5>
                      <small>Total Liquidado</small>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <div
                    class="d-flex align-items-center mr-2 hoverzim"
                    @click="filterHeader"
                  >
                    <b-avatar
                      variant="light-warning"
                      rounded
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ count.a_vencer.count }}
                      </h5>
                      <small>Parcelas A vencer</small>
                    </div>
                  </div>
                </b-col>

                <b-col>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-warning"
                      rounded
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ valorBr(count.a_vencer.total, true) }}
                      </h5>
                      <small>Total A vencer</small>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mt-1">
                  <b-button
                    class="mb-1"
                    variant="outline-warning"
                    :disabled="!items.length > 0"
                    @click="exportXlsx"
                  >
                    <span class="align-middle">Exportar</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="4">
              <b-row class="mt-1">
                <b-col>
                  <strong>Quantidade Parcelas</strong>
                </b-col>
                <b-col>
                  <span>{{ count.parcelas }}</span>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <strong>Quantidade Clientes</strong>
                </b-col>
                <b-col>
                  <span>{{ count.clientes }}</span>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <strong>Total</strong>
                </b-col>
                <b-col>
                  <span>{{ valorBr(count.total, true) }}</span>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <strong>Início Filtro</strong>
                </b-col>
                <b-col>
                  <span>{{ filtro.inicio ? americaDate(filtro.inicio) : null }}</span>
                </b-col>
              </b-row>

              <b-row class="mt-1">
                <b-col>
                  <strong>Final Filtro</strong>
                </b-col>
                <b-col>
                  <span>{{ filtro.fim ? americaDate(filtro.fim) : null }}</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-1">
            <b-col
              md="3"
            >
              <label for="">Filtro por Status</label>
              <v-select
                v-model="statusFilter"
                :options="optionStatus"
                multiple
              />
            </b-col>
            <b-col md="3">
              <label for="">Filtro por Executivo</label>
              <v-select
                v-model="statusExecutivo"
                :options="optionExecutivo"
                multiple
              />
            </b-col>
          </b-row>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="itemsFiltered"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(vencimento)="data">
              {{ data.item.vencimento ? americaDate(data.item.vencimento) : '' }}
            </template>

            <template #cell(recebimento)="data">
              {{ data.item.recebimento ? americaDate(data.item.recebimento) : '' }}
            </template>

            <template #cell(principal)="data">
              {{ data.item.principal ? valorBr(resolveRound(data.item.principal), true) : '' }}
            </template>

            <template #cell(realizado)="data">
              {{ data.item.realizado ? valorBr(resolveRound(data.item.realizado), true) : '' }}
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="itemsFiltered.length"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-filtro"
      size="md"
      ok-only
      ok-title="filtrar"
      @ok="getParcelas()"
    >
      <b-row>
        <b-col md="12">
          <label>Credor</label>
          <v-select
            v-model="filtro.credor"
            multiple
            :options="credores"
            label="nome"
            :close-on-select="false"
          />
        </b-col>
        <b-col md="6">
          <label>Forma de Pagamento</label>
          <v-select
            v-model="filtro.forma_pagamento"
            multiple
            :options="formaPagamento"
          />
        </b-col>
        <b-col md="6">
          <label>Tipo de Pagamento</label>
          <v-select
            v-model="filtro.tipo_pagamento"
            multiple
            :options="tipoPagamento"
          />
        </b-col>
        <b-col
          md="6"
          class="mt-1"
        >
          <label for="">Vencimento Início</label>
          <b-input-group class="mb-1">
            <b-form-input
              id="example-input"
              :value="filtro.inicio ? americaDate(filtro.inicio) : ''"
              type="text"
              size="sm"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.inicio"
                button-only
                right
                size="sm"
                locale="en-US"
                class="mb-1"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>

        <b-col
          md="6"
          class="mt-1"
        >
          <label for="">Vencimento Fim</label>
          <b-input-group class="mb-1">
            <b-form-input
              id="example-input"
              :value="filtro.fim ? americaDate(filtro.fim) : ''"
              type="text"
              size="sm"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.fim"
                button-only
                right
                size="sm"
                locale="en-US"
                class="mb-1"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="6">
          <label for="">Recebimento Início</label>
          <b-input-group class="mb-1">
            <b-form-input
              id="example-input"
              :value="filtro.recebimento_inicio ? americaDate(filtro.recebimento_inicio) : ''"
              type="text"
              size="sm"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.recebimento_inicio"
                button-only
                right
                size="sm"
                locale="en-US"
                class="mb-1"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col md="6">
          <label for="">Recebimento Fim</label>
          <b-input-group class="mb-1">
            <b-form-input
              id="example-input"
              :value="filtro.recebimento_fim ? americaDate(filtro.recebimento_fim) : ''"
              type="text"
              size="sm"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.recebimento_fim"
                button-only
                right
                size="sm"
                locale="en-US"
                class="mb-1"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BAvatar,
  BPagination,
  BModal,
  VBModal,
  BFormDatepicker,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import api from '@/../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BAvatar,
    BPagination,
    BModal,
    BFormDatepicker,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      fields: [
        { key: 'instituicao', class: 'text-center' },
        { key: 'parcela_status', class: 'text-center' },
        { key: 'forma_pagamento', class: 'text-center' },
        { key: 'cliente', class: 'text-center' },
        { key: 'recebimento', class: 'text-center' },
        { key: 'vencimento', class: 'text-center' },
        { key: 'realizado', class: 'text-center' },
        { key: 'condicao', label: 'CONDIÇÃO', class: 'text-center' },
        { key: 'executivo', class: 'text-center' },
      ],
      items: [],
      credores: [],
      filter: null,
      statusFilter: [],
      statusExecutivo: [],
      formaPagamento: [
        { label: 'Boleto', key: 'Boleto' },
        { label: 'Cartão', key: 'cartao_voz' },
        { label: 'Pix', key: 'pix' },
      ],
      tipoPagamento: [
        { label: 'Conta Voz' },
        { label: 'Conta Credor' },
        { label: 'Acordo Voz - pgt na instituição' },
        { label: 'Baixa administrativa' },
      ],
      /*
        TOTAL E QUANTIDADE DE LIQUIDADO, CANCELADO, A VENCER E AGUARDANDO
        TOTAL GERAL DE PARCELAS
        TOTAL DE CLIENTES
      */

      count: {
        liquidado: { total: 0, count: 0 },
        a_vencer: { total: 0, count: 0 },
        cancelado: { total: 0, count: 0 },
        aguardando: { total: 0, count: 0 },
        parcelas: 0,
        total: 0,
        clientes: 0,
      },

      filtro: {
        inicio: null,
        fim: null,
        credor: null,
        tipo_pagamento: null,
        forma_pagamento: null,
        recebimento_inicio: null,
        recebimento_fim: null,
      },

      perPage: 15,
      currentPage: 1,
      optionStatus: ['A Vencer', 'Liquidada'],
    }
  },
  computed: {
    optionExecutivo() {
      const { items } = this
      const statusExecutivo = []

      items.map(item => {
        item.executivo ? statusExecutivo.push(item.executivo) : null
      })
      const acordosFiltered = [...new Set(statusExecutivo)]
      return acordosFiltered
    },
    itemsFiltered() {
      const { items, statusFilter: status, statusExecutivo } = this
      let listItems = items

      if (status.length > 0 || statusExecutivo.length > 0) {
        listItems = []
        // filtro por status
        for (let i = 0; i <= status.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          items.map(item => {
            if (item.parcela_status === status[i]) {
              listItems.push(item)
            }
          })
        }
        // filtro por executivo
        for (let i = 0; i <= statusExecutivo.length - 1; i++) {
          let newFilter = []
          listItems.length > 0 ? (newFilter = listItems) : (newFilter = items)
          const listFilter = []
          newFilter.map(item => {
            if (item.executivo === statusExecutivo[i]) {
              listFilter.push(item)
            }
          })
          listItems = listFilter
        }
      }
      listItems.sort((a, b) => {
        if (a.id < b.id) {
          return 1
        }
        if (a.id > b.id) {
          return -1
        }
        return 0
      })
      return listItems
    },
  },
  created() {
    const date = new Date()
    const fim = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().slice(0, 10)
    // const dayMs = 86400000 // Número de milisegundos em um dia
    const inicio = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().slice(0, 10)
    this.filtro.inicio = inicio
    this.filtro.fim = fim
    this.getParcelas()
    this.getCredores()
  },
  methods: {
    getParcelas() {
      const {
        inicio,
        fim,
        credor,
        tipo_pagamento: tipoPG,
        forma_pagamento: formaPG,
        recebimento_inicio,
        recebimento_fim,
      } = this.filtro

      const credorId = []
      credor ? credor.map(id => {
        credorId.push(id.id)
      }) : null

      const nomeForma = []
      formaPG ? formaPG.map(key => {
        nomeForma.push(key.key)
      }) : null

      const nomeTipo = []
      tipoPG ? tipoPG.map(label => {
        nomeTipo.push(label.label)
      }) : null
      const body = {
        data_inicial: inicio,
        data_final: fim,
        credor_id: credorId,
        forma_pagamento: nomeForma,
        status_recebimento: nomeTipo,
        data_inicial_baixa: recebimento_inicio,
        data_final_baixa: recebimento_fim,
      }
      api.post('api/v1/parcelas/listagem', body, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        // this.items = res.data
        const liquidado = { total: 0, count: 0 }
        // eslint-disable-next-line camelcase
        const a_vencer = { total: 0, count: 0 }
        // const cancelado = { total: 0, count: 0 }
        // const aguardando = { total: 0, count: 0 }
        const itemsWhithoutCanceled = []
        res.data.map(items => {
          if (items.acordo_status !== 'Cancelado') {
            itemsWhithoutCanceled.push(items)
          }
        })
        let total = 0

        let clientes = []
        itemsWhithoutCanceled.map(item => {
          clientes.push(item.cliente_id)

          total += parseFloat(item.realizado)

          switch (item.acordo_status) {
            case 'Liquidado':
              liquidado.total += parseFloat(item.realizado)
              liquidado.count += 1
              break

              // case 'Aguardando':
              //   a_vencer.total += parseFloat(item.realizado)
              //   a_vencer.count += 1
              //   break

            // case 'Cancelado':
            //   cancelado.total += parseFloat(item.realizado)
            //   cancelado.count += 1
            //   break
            default:
              break
          }
          switch (item.parcela_status) {
            // case 'A vencer':
            //   a_vencer.total += parseFloat(item.realizado)
            //   a_vencer.count += 1
            //   break
            case 'A Vencer':
              a_vencer.total += parseFloat(item.realizado)
              a_vencer.count += 1
              break
            default:
              break
          }
        })

        clientes = [...new Set(clientes)]
        this.count.clientes = clientes.length
        this.count.liquidado = liquidado
        // this.count.aguardando = aguardando
        // eslint-disable-next-line camelcase
        this.count.a_vencer = a_vencer
        this.count.parcelas = itemsWhithoutCanceled.length
        // this.count.cancelado = cancelado
        this.count.total = total

        this.items = itemsWhithoutCanceled
      })
    },
    resolveRound(num) {
      parseFloat(num)
      return +(`${Math.round(`${num}e+2`)}e-2`)
    },
    async getCredores() {
      api.get('api/v1/credores/', {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      }).then(res => {
        res.data.dados.map(credor => {
          this.credores.push({
            id: credor.id,
            nome: credor.nome,
          })
        })
      })
    },
    filterHeader(e) {
      const smallElement = e.currentTarget.querySelector('small').textContent
      if (this.statusFilter.length !== 0) {
        this.statusFilter = []
        return
      }
      if (smallElement.includes('vencer')) {
        this.statusFilter = [this.optionStatus[0]]
        return
      }
      this.statusFilter = [this.optionStatus[1]]
    },
    converterMoeda(moeda) {
      const intMoeda = parseFloat(moeda)
      if (intMoeda) {
        const valor = intMoeda.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        return valor
      }
      const valor = intMoeda.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      return valor
    },
    formatarData(dados) {
      const a = dados.split(' ')
      const b = a[0].split('-')
      const dataBr = `${b[2]}/${b[1]}/${b[0]}`
      return dataBr
    },
    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    async getXlsx() {
      const { fim, recebimento_fim } = this.filtro
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Parcelas')
      const itemsTable = this.itemsFiltered

      worksheet.columns = [
        { header: 'Instituição de Ensino', key: 'instituicao' },
        { header: 'Cliente', key: 'cliente' },
        { header: 'Acordo', key: 'acordo_id' },
        { header: 'Data Acordo', key: 'data_acordo' },
        { header: 'Vencimento', key: 'vencimento' },
        { header: 'Pagamento', key: 'recebimento' },
        { header: 'Parcela', key: 'parcela_id' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Forma Pagamento', key: 'forma_pagamento' },
        { header: 'Status Acordo', key: 'acordo_status' },
        { header: 'CONDIÇÃO', key: 'condicao' },
        { header: 'Status Parcela', key: 'parcela_status' },
        { header: 'Status Recebimento', key: 'status_recebimento' },
        { header: 'Executivo', key: 'executivo' },
      ]
      const wk1Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        'comissao',
        'repasse',
        'taxa_cartao',
      ]
      const totais = {
        wk1: {},
      }

      itemsTable.map(item => {
        worksheet.addRow([
          item.instituicao,
          item.cliente,
          item.acordo_id,
          this.formatarData(item.data_acordo),
          this.formatarData(item.vencimento),
          item.recebimento !== null ? this.formatarData(item.recebimento) : null,
          item.parcela_id,
          item.principal !== 'NaN' ? parseFloat(item.principal) : '',
          parseFloat(item.juros),
          parseFloat(item.multa),
          item.desconto !== 'NaN' ? parseFloat(item.desconto) : '',
          parseFloat(item.honorarios),
          parseFloat(item.realizado),
          parseFloat(item.comissao),
          item.repasse !== 'NaN' ? parseFloat(item.repasse) : '',
          parseFloat(item.taxa_cartao),
          item.forma_pagamento,
          item.acordo_status,
          item.condicao,
          item.parcela_status,
          item.status_recebimento,
          item.executivo,
        ])

        wk1Columns.map(w => {
          totais.wk1[w] ? totais.wk1[w] += parseFloat(item[w]) : totais.wk1[w] = parseFloat(item[w])
        })
      })
      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      const totalRowWk1 = worksheet.addRow({
        principal: totais.wk1.principal,
        juros: totais.wk1.juros,
        multa: totais.wk1.multa,
        desconto: totais.wk1.desconto,
        honorarios: totais.wk1.honorarios,
        realizado: totais.wk1.realizado,
        comissao: totais.wk1.comissao,
        repasse: totais.wk1.repasse,
        taxa_cartao: totais.wk1.taxa_cartao,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
        cell.alignment = {
          horizontal: 'center',
          vertical: 'center',
        }
      })
      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], `Relatório de parcelas - ${this.formatarData(!fim ? recebimento_fim : fim)}`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    clearFilter() {
      this.filtro.credor = ''
      this.filtro.tipo_pagamento = ''
      this.filtro.forma_pagamento = ''
      this.filtro.inicio = ''
      this.filtro.fim = ''
      this.filtro.recebimento_inicio = ''
      this.filtro.recebimento_fim = ''
    },
  },
}
</script>
<style>
  .hoverzim {
    transition: all 0.2s ease;
  }
  .hoverzim:hover {
    transform: scale(1.03);
    opacity: 0.9;
  }
</style>
